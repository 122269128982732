<template>
  <brand-logos-carousel
    :items="[
      'airbnb',
      'blablacar',
      'linkedin',
      'google',
      'qonto',
      'paypal',
      'malt',
      'doctolib',
      'payfit',
      'algolia',
    ]"
    :title="$t('teacher.companies_logo_banner_title')"
    v-bind="properties"
  />
</template>

<script lang="ts" setup>
import type { BrandLogoCarouselShareableProps } from "~/business-areas/brand/brand.model";

const properties = defineProps<BrandLogoCarouselShareableProps>();
</script>
